import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('articles.liste_article'),

            vuetable: {
                moreParams: {
                    magasin: ""
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center',
                    //     width: "30px"
                    // },
                    {
                        name: 'ref_article',
                        title: this.$t('articles.reference'),
                        sortField: 'ref_article',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'designation',
                        title: this.$t('articles.designation'),
                        sortField: 'designation',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'nom_famille',
                        title: this.$t('articles.famille'),
                        sortField: 'nom_famille',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'stock_previsionnel',
                        title: "Stock",
                        sortField: 'stock_previsionnel',
                        dataClass: 'text-right'
                    },
                    {
                        name: 'pu_vente',
                        title: 'P.U Vente',
                        sortField: 'pu_vente',
                        dataClass: 'text-right text-bold text-danger',
                        width: "150px"
                    },
                    {
                        name: 'prix_vente_gros',
                        title: 'Prix Revendeur',
                        sortField: 'prix_vente_gros',
                        dataClass: 'text-right text-bold',
                        width: "150px"
                    }

                ],
                sortOrder: [
                    { field: 'designation', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Référence de l\'article': 'ref_article',
                    'Désignation': 'designation',
                    'Famille': 'nom_famille',
                    'Stock Réel': 'stock_reel',
                    'Stock Previsionnel': 'stock_previsionnel',
                    'PU Vente': 'pu_vente',
                    'PU Revendeur': 'prix_vente_gros'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                ref_article: "",
                designation: "",
                stock_securite: 10,
                prix_revient: "",
                marge_pourcentage: "",
                marge_monetaire: "",
                tva: 0,
                pu_vente: "",
                articles_famille_id: "",
                stock_reel: "",
                stock_previsionnel: ""
            },
            crudmodaltitle: "Ajouter une nouvelle article",
            listdata: {
                familles: [],
                magasins: [],
                magasin_defaut: "",
            },
            magasin_id: ""

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.magasin = this.magasin_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.magasin = this.magasin_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Ajouter une article";
            this.crudform = {
                id: "",
                ref_article: "",
                designation: "",
                stock_securite: 10,
                prix_revient: "",
                marge_pourcentage: "",
                marge_monetaire: "",
                tva: 0,
                pu_vente: "",
                articles_famille_id: "",
                stock_reel: "",
                stock_previsionnel: ""
            };
            if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                this.crudform.magasin_id = this.listdata.magasin_defaut + '';
            }
            this.openModal();
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/articlesclient/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.magasins = response.data.magasins;
                console.log(response.data.magasins);

                this.listdata.magasin_defaut = response.data.magasin_defaut;

                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                    this.magasin_id = this.crudform.magasin_id;
                }
                if (this.listdata.magasins.length > 0 && this.listdata.magasin_defaut == '') {
                    this.magasin_id = this.listdata.magasins[0].id;
                }
                this.setFilter();
            });
        },
        onChangeLoad: function(status, userData) {
            console.log(status);
            console.log(userData);
        },
        onChangeprixrevient: function() {
            var newvalue = this.crudform.prix_revient;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (parseFloat(this.crudform.marge_pourcentage) > 0) {
                this.crudform.marge_monetaire = parseFloat(newvalue) * parseFloat(this.crudform.marge_pourcentage) / 100;
            }

            if (this.crudform.marge_monetaire == '' || this.crudform.marge_monetaire == null) {
                this.crudform.marge_monetaire = 0;
            }
            console.log(newvalue);
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + parseFloat(this.crudform.marge_monetaire);
        },
        onChangemargepourcentage: function() {

            var newvalue = this.crudform.marge_pourcentage;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (this.crudform.prix_revient == '' || this.crudform.prix_revient == null) {
                this.crudform.prix_revient = 0;
            }
            this.crudform.marge_monetaire = parseFloat(this.crudform.prix_revient) * parseFloat(newvalue) / 100;
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + this.crudform.marge_monetaire;
        },
        onChangemargemonetaire: function() {

            var newvalue = this.crudform.marge_monetaire;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (this.crudform.prix_revient == '' || this.crudform.prix_revient == null) {
                this.crudform.prix_revient = 0;
            }
            if (this.crudform.marge_monetaire == '' || this.crudform.marge_monetaire == null) {
                this.crudform.marge_monetaire = 0;
            }
            if (parseFloat(this.crudform.prix_revient) > 0) {
                this.crudform.marge_pourcentage = parseFloat(newvalue) * 100 / parseFloat(this.crudform.prix_revient);
            }
            this.crudform.pu_vente = parseFloat(this.crudform.prix_revient) + parseFloat(newvalue);
        },
        onChangepuvente: function() {
            var newvalue = this.crudform.pu_vente;
            if (newvalue == '' || newvalue == null) {
                newvalue = 0;
            }
            if (parseFloat(this.crudform.prix_revient) > 0) {
                this.crudform.marge_monetaire = parseFloat(newvalue) - parseFloat(this.crudform.prix_revient);
                this.crudform.marge_pourcentage = parseFloat(this.crudform.marge_monetaire) * 100 / parseFloat(this.crudform.prix_revient);
            }
        },
        onRowClass(dataItem) {
            if (dataItem.stock_previsionnel < dataItem.stock_securite || dataItem.stock_reel < dataItem.stock_securite) {
                return "red-row";
            }
            return "";
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        console.log(this.$parent.role_user);

        this.motCle = "";
        this.criteriacol = "";

        this.fetchData();
    },

}